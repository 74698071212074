<template>
  <div class="page_container">
    <div class="page_head">
      <div class="page_head-title">COMMUNITY</div>
    </div>
    <div class="page_content">

      <div class="page_filters">
        <div class="page_filters-title">Filters</div>
        <div class="filters">
          <b-form-select
              v-model="filters.subject"
              class=""
              value-field="id"
              text-field="materiaDesc"
              :options="subjects">
            <template #first>
              <b-form-select-option value="">SUBJECT</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
              v-model="filters.language"
              class=""
              value-field="id"
              text-field="language"
              :options="languages">
            <template #first>
              <b-form-select-option value="" disabled>LANGUAGE</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
              v-model="filters.class"
              class=""
              value-field="key"
              text-field="value"
              :options="classes">
            <template #first>
              <b-form-select-option :value="null" disabled>SCHOOL ORDER</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
              v-model="filters.topic"
              class=""
              :options="topics">
            <template #first>
              <b-form-select-option :value="null" disabled>TOPIC</b-form-select-option>
            </template>
          </b-form-select>
          <b-form-select
              v-model="filters.teacher"
              class=""
              value-field="doc_id"
              text-field="full_name"
              :options="teachers">
            <template #first>
              <b-form-select-option :value="null" disabled>TEACHER</b-form-select-option>
            </template>
          </b-form-select>
        </div>
      </div>

      <div class="page_community">
        <div class="page_community-title">Order by: <span class="page_community-title_sub">New ost rated</span></div>
        <div class="communities">
          <div class="community_item" v-for="item of filteredItems">
            <div class="community_item-head">
              <div class="community_item-name">{{ item.user.name }}&nbsp; {{ item.user.surname }}</div>
              <div class="community_item-date">{{getDate(item.created_at)}}</div>
            </div>
            <div class="community_item-body">
              <div class="community_img"></div>
              <div class="community_content">
                <div class="community_content-title">{{item.lesson_title}}</div>
                <div class="community_content-topic">{{item.topic}}</div>
                <div class="community_content-description">Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed
                  diam nonummy nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut wisi enim ad
                  minim veniam
                </div>
              </div>
              <div class="community_subject">
                <div>Subject</div>
                <div>School order (Elementary or middle or high school)</div>
              </div>
            </div>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
import {mapActions} from "vuex";

export default {
  name: "Community",
  data() {
    return {
      filters: {
        subject: '',
        language: '',
        class: null,
        topic: null,
        teacher: null
      },
      subjects: [],
      languages: [
        {
          id: 1,
          language: 'English'
        },
        {
          id: 2,
          language: 'Spanish'
        },
        {
          id: 3,
          language: 'Italian'
        }
      ],
      classes: [],
      topics: [],
      teachers: [],
      communities: []
    }
  },
  computed: {
    filteredItems() {
      let filteredItems = this.communities.filter((item) => {
        return item.subject_id == this.filters.subject;
      })
      // let dateFilter = filteredItems.filter((item) => {
      //   if(this.date_selected !== '') {
      //     return new Date(item.created_at).getTime() >=  this.date_selected
      //   } else {
      //     return item
      //   }
      // })
      // let orderedStatus = dateFilter.filter((statusItem) => {
      //   if(this.active_text.value == 'Active') {
      //     return statusItem.statusInFB == '1' && statusItem.compaignId
      //   } else if(this.active_text.value == 'Paused') {
      //     return statusItem.statusInFB == '0' && statusItem.compaignId
      //   } else if(this.active_text.value == 'Waiting for approval'){
      //     return statusItem.statusInFB == '0' && !statusItem.compaignId
      //   } else if(this.active_text.value == 'All'){
      //     return statusItem
      //   }
      // })
      // let chosenClient = orderedStatus.filter((client) => {
      //   if(this.activeClient.id) {
      //     return client.client == this.activeClient.id
      //   } else {
      //     return client
      //   }
      // })
      // let chosenTag = chosenClient.filter((tag) => {
      //   if (this.activeTag.id) {
      //     return  tag.tags.find(t => (this.activeTag.tag_name === t))
      //   } else {
      //     return tag
      //   }
      // })
      //
      // let orderedItems = chosenTag.sort((a, b) => {
      //   return b.job_title - a.job_title;
      // })
      // return filteredItems;
      return this.communities;
    },
  },
  created() {
    this.getCommunityFilterKeys()
    this.getAllCommunities()
  },
  methods: {
    ...mapActions(['getCommunityFilters', 'getCommunities']),
    getDate(item) {
      let d = new Date(item).toLocaleString()
      return d
    },
    getCommunityFilterKeys() {
      this.getCommunityFilters()
          .then((res) => {
            if (res) {
              this.subjects = res.data.subject
              this.classes = res.data.classes
              this.topics = res.data.topic
              this.teachers = res.data.teacher
            }
          })
    },
    getAllCommunities() {
      this.getCommunities()
          .then((res) => {
            this.communities = res.data
          })
    }
  }
}
</script>

<style scoped lang="scss">
.page {
  &_container {
    display: flex;
    flex-direction: column;
  }

  &_head {
    width: 100%;
    height: 73px;
    background: linear-gradient(180deg, #003761 0%, #00528C 79.64%);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 30px;
    position: sticky;
    top: 0;
    z-index: 3;

    &-title {
      color: #FFFFFF;
      font-size: 20px;
      font-style: normal;
      font-weight: 400;
      line-height: normal;
    }
  }

  &_content {
    width: 100%;
    display: flex;
    //justify-content: space-between;
    padding: 80px 60px;
  }

  &_filters {
    width: 40%;

    &-title {
      color: #11334D;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 10px;
    }

    & .filters {
      & select {
        max-width: 440px;
        width: 100%;
        height: 52px;
        border-radius: 8px;
        border: 1px solid #11334D;
        background: url("~@/assets/icons/arrow_down.svg") no-repeat right 1.1rem center/24px 25px;
        margin: 14px 0;
        color: #11334D;
        font-size: 19px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        text-align: start;
      }
    }
  }

  &_community {
    width: 45%;

    &-title {
      color: #11334D;
      font-size: 28px;
      font-style: normal;
      font-weight: 700;
      line-height: normal;
      padding-bottom: 10px;

      &_sub {
        color: #11334D;
        font-size: 17px;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
      }
    }

    & .communities {
      & .community {
        &_item {
          margin: 15px 0;
          width: 100%;
          height: 300px;
          border: 1px solid #003761;
          border-radius: 12px;
          padding: 15px;

          &-head {
            display: flex;
            justify-content: space-between;
            padding: 10px 20px;
          }

          &-body {
            padding: 15px 0;
            display: flex;
            justify-content: space-between;

          }

          &-name,
          &-date {
            color: #11334D;
            font-size: 17px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
          }

        }
        &_content {
          width: 300px;
          &-title,
          &-topic {
            color: #11334D;
            font-size: 19px;
            font-style: normal;
            font-weight: 700;
            line-height: normal;
            padding-bottom: 4px;
          }
          &-description {
            padding-top: 10px;
            color: #000;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: normal;
            max-height: 145px;
            overflow-y: auto;
          }
        }
        &_subject {
          width: 150px;
          height: 170px;
          border: 1px solid #003761;
          padding: 15px 10px;
          color: #11334D;
          font-size: 14px;
          font-style: normal;
          font-weight: 700;
          line-height: normal;
        }
        &_img {
          width: 150px;
          height: 170px;
          background: #00F;
        }

      }
    }
  }
}
</style>